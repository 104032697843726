import { ThemeProvider } from 'styled-components';
import { saasModernTheme } from 'common/src/theme/saasModern';
import React, { Fragment } from 'react';
import SEO from '../../components/seo';
import { ResetCSS } from 'common/src/assets/css/style';
import { ContentWrapper, GlobalStyle } from '../../containers/SaasModern/sassModern.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../../containers/SaasModern/Navbar';
import Footer from '../../containers/SaasModern/Footer';
import Container from 'common/src/components/UI/Container';
import Box from 'common/src/components/Box';
import DummyBanner from '../../containers/SaasModern/Banner/DummyBanner';

export default () => {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO title="Press resources"/>

        <ResetCSS/>
        <GlobalStyle/>

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar/>
            </DrawerProvider>
          </Sticky>
          <DummyBanner titleText="Press resources"
                       subtitleText={<>Writing about B2B SaaS Leads? Here's a few details to get you started!
                         <br/>Feel free to reach to us at hello@b2bsaasleads.com</>}/>

          <Container>
            <Box style={{ margin: '30px' }}>
              <h2>Short description</h2>
              <p>
                Built a B2B SaaS and wondering how to get customers?
                <br/><br/>
                B2BSaaSLeads.com gives you a list of potential customers who have money and are
                already using similar technologies.
                <br/><br/>
                Start selling in less than 60 seconds 🚀
              </p>
              <h2>Boilerplate description</h2>
              <p>
                Once you've launched your SaaS, the next thing you're probably thinking is "What
                comes next? How do I find more customers?"
                <br/><br/>
                B2B SaaS Leads lets you quickly download a list of
                customers who have money, are already using similar tools - and start selling 🚀
                <br/><br/>
                This is specially useful if you know what other tech your customers might be using:
                <br/>👉 Launching a Google Analytics competitor? Download a list of all GA
                customers!
                <br/>👉 Building for the e-commerce space? Get a list of everyone who's using
                shopify or
                woocommerce!
                <br/>👉 Building a nocode tool? Who'll be your best customers? That's right,
                customers of
                other nocode tools!
                <br/><br/>
                One click and you get a CSV in your email with all that you need. A list of
                websites, company details, funding & revenue info, CEO details etc. All that's left
                for you to do is to pick up the phone or start writing emails! Happy selling!
              </p>
              <h2>Logo</h2>
              <img src="/icons/icon-144x144.png"/>
            </Box>
          </Container>
          <Footer/>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
